import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import deviceMarketplaceStoreModule from '../deviceMarketplaceStoreModule'

export default function useDevicesMarketplaceForm() {
  const DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME = 'app-devices-marketplace'

  // Register module
  if (!store.hasModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME, deviceMarketplaceStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICES_MARKETPLACE_APP_STORE_MODULE_NAME)
  })

  //  TODO: add functionality for images
  const blankMarketplaceProductData = {
    name: null,
    nameAr: null,
    description: null,
    descriptionAr: null,
    maxQuantity: null,
    thumbnailPhotoUrl: '',
    price: null,
    url1: '',
    url2: '',
    url3: '',
    url4: '',
  }

  const marketplaceProductData = ref(JSON.parse(JSON.stringify(blankMarketplaceProductData)))

  const marketplaceProductOptions = computed(() => store.getters['app-devices-marketplace/marketplaceProductOptions'])

  // *===============================================---*
  // *--------- ACTIONS ---------------------------------------*
  // *===============================================---*

  const fetchMarketplaceProducts = async () => {
    try {
      const response = await store.dispatch('app-devices-marketplace/fetchMarketplaceProducts')
      return response
    } catch (error) {
      return error
    }
  }

  const fetchMarketplaceProduct = async () => {
    try {
      const response = await store.dispatch('app-devices-marketplace/fetchMarketplaceProduct',  router.currentRoute.params.id)
      // eslint-disable-next-line prefer-destructuring
      marketplaceProductData.value = response.data.data[0]
    } catch (error) {
      if (error.response.status === 404) {
        marketplaceProductData.value = undefined
      }
    }
  }

  const createMarketplaceProduct = async () => {
    try {
      const response = await store.dispatch('app-devices-marketplace/createMarketplaceProduct', marketplaceProductData.value)
      if (!response.data.status) {
        return
      }
      router.push({ name: 'general-settings-devices-marketplace-list' })
    } catch (error) {
      console.error(error)
    }
  }

  const updateMarketplaceProduct = async () => {
    try {
      const response = await store.dispatch('app-devices-marketplace/updateMarketplaceProduct', marketplaceProductData.value)
      if (response.data.status) {
        router.push({ name: 'general-settings-devices-marketplace-list' })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    //  Data
    marketplaceProductData,

    //  Options
    marketplaceProductOptions,

    //  Actions
    fetchMarketplaceProducts,
    fetchMarketplaceProduct,
    createMarketplaceProduct,
    updateMarketplaceProduct,
  }
}
