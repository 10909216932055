<template>
  <b-row>

    <!-- Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required"
      >
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="marketplaceProductData.name"
            :state="getValidationState(validationContext)"
            placeholder="Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Name AR -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Name AR"
        rules="required"
      >
        <b-form-group
          label="Name AR"
          label-for="name-ar"
        >
          <b-form-input
            id="name-ar"
            v-model="marketplaceProductData.nameAr"
            :state="getValidationState(validationContext)"
            placeholder="NameAr"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="marketplaceProductData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Description AR -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description AR"
        rules="required"
      >
        <b-form-group
          label="Description AR"
          label-for="description-ar"
        >
          <b-form-textarea
            id="description-ar"
            v-model="marketplaceProductData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="Description AR"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Price -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Price"
        rules="required"
      >
        <b-form-group
          label="Price"
          label-for="price"
        >
          <b-form-input
            id="price"
            v-model="marketplaceProductData.price"
            type="number"
            :state="getValidationState(validationContext)"
            placeholder="Price"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Max Quantity -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Max Quantity"
        rules="required|integer"
      >
        <b-form-group
          label="Max Quantity"
          label-for="max-quantity"
        >
          <b-form-input
            id="max-quantity"
            v-model="marketplaceProductData.maxQuantity"
            type="text"
            :state="getValidationState(validationContext)"
            placeholder="Max Quantity"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Status -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Status"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Status"
          label-for="status"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="marketplaceProductData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="status"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col sm="6">
      <div class="d-flex overflow-auto">

        <s-3-image-uploader
          :accept="'.jpg, .png, .gif'"
          :obj="marketplaceProductData"
          :field-name="'thumbnailPhotoUrl'"
          :label="'Thumbnail Image'"
          :directory="'devices-marketplace'"
          class="mr-1"
        />

        <!-- Image URL 1 -->
        <s-3-image-uploader
          v-if="marketplaceProductData.url1 || (marketplaceProductData.thumbnailPhotoUrl)"
          class="mr-1"
          :accept="'.jpg, .png, .gif'"
          :obj="marketplaceProductData"
          :field-name="'url1'"
          :label="'Device Image 1'"
          :directory="'devices-marketplace'"
        />

        <!-- Image URL 2 -->
        <s-3-image-uploader
          v-if="marketplaceProductData.url2 || (marketplaceProductData.thumbnailPhotoUrl && marketplaceProductData.url1)"
          class="mr-1"
          :accept="'.jpg, .png, .gif'"
          :obj="marketplaceProductData"
          :field-name="'url2'"
          :label="'Device Image 2'"
          :directory="'devices-marketplace'"
        />

        <!-- Image URL 3 -->
        <s-3-image-uploader
          v-if="marketplaceProductData.url3 || (marketplaceProductData.thumbnailPhotoUrl && marketplaceProductData.url1 && marketplaceProductData.url2)"
          class="mr-1"
          :accept="'.jpg, .png, .gif'"
          :obj="marketplaceProductData"
          :field-name="'url3'"
          :label="'Device Image 3'"
          :directory="'devices-marketplace'"
        />

        <!-- Image URL 4 -->
        <s-3-image-uploader
          v-if="marketplaceProductData.url4 || (marketplaceProductData.thumbnailPhotoUrl && marketplaceProductData.url1 && marketplaceProductData.url2 && marketplaceProductData.url3)"
          class="mr-1"
          :accept="'.jpg, .png, .gif'"
          :obj="marketplaceProductData"
          :field-name="'url4'"
          :label="'Device Image 4'"
          :directory="'devices-marketplace'"
        />
      </div>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import S3ImageUploader from '@/components/s3/s3-image-uploader/s3ImageUploader.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,

    vSelect,

    //  Form Validation
    ValidationProvider,
    S3ImageUploader,
  },

  directives: {
    Ripple,
  },
  props: {
    marketplaceProductData: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      //  Form
      getValidationState,
    }
  },
}
</script>
